import React, { Component } from "react";
import { Link , useLocation } from "gatsby";
import logoImage from "../images/jci-logo.png";
class NavOne extends Component {
  constructor() {
    super();
    this.state = {
      sticky: false,
    };
  }

  

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    

    //Mobile Menu
    this.mobileMenu();

  }
  

  handleScroll = () => {
    if (window.scrollY > 100) {
      this.setState({
        sticky: true,
      });
    } else if (window.scrollY < 100) {
      this.setState({
        sticky: false,
      });
    }
  };

  mobileMenu = () => {
    //Mobile Menu Toggle
    let mainNavToggler = document.querySelector(".mobile-menu-toggle");
    let mainNav = document.querySelector(".side-nav-container");

    mainNavToggler.addEventListener("click", function () {
      mainNav.classList.add("active");
    });

    //Close Mobile Menu
    let closeMenu = document.querySelector(".side-menu-close");
    closeMenu.addEventListener("click", function () {
      mainNav.classList.remove("active");
    });
  };

  render() {
    return (
      <div>
        <header className="header-area">
          <div className="header-top-action">
            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  <div className="top-action-content">
                    <div className="info-box info-box-1 d-flex align-items-center">
                      <ul className="d-flex align-items-center">
                        <li>
                          <a href="mailto:foundation@jciindia.in">
                            <i className="fa fa-envelope"></i>
                            foundation@jciindia.in
                          </a>
                        </li>
                        <li>
                          <a href="tel:022-71117112">
                            <i className="fa fa-phone-square"></i>(022)-71117112
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="top-action-content info-action-content">
                    <div className="info-box info-box-2 d-flex align-items-center justify-content-end">
                      <ul className="top-action-list d-flex align-items-center">
                        {/* <li className="action__text">
                          <a href="#none">login</a>
                        </li>
                        <li className="action__text">
                          <a href="#none">register</a>
                        </li> */}
                        <li>
                          <a href="#none">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#none">
                            <i className="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#none">
                            <i className="fa fa-youtube-play"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#none">
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`header-top header-menu-action ${this.state.sticky ? "header-fixed" : ""
              }`}
          >
            <div className="container">
              <div className="row ostion-top-wrap">
                <div className="col-lg-4 col-sm-4 site-branding">
                  <div className="logo-action d-flex align-items-center">
                    <div className="ostion-logo">
                      <Link to="/">
                        <img src={logoImage} alt="JCI India" title="JCI India" />
                      </Link>
                    </div>
                    <div className="header-btn ml-auto">
                      <Link  to="/donate" className="theme-btn">
                        donate now
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-sm-8 ostion-menu">
                  <div className="ostion-menu-innner">
                    <div className="ostion-menu-content">
                      <div className="navigation-top">
                        <nav className="main-navigation">
                          <ul>
                            <li className="active">
                              <Link to="/">Home</Link>
                            </li>
                            <li>
                              <a href="#none">About us</a>
                              <ul className="dropdown-menu-item">
                                <li>
                                  <Link onClick={() => window.scrollTo(0, 0)} to="/about-us/why-jci">Why JCI</Link>
                                </li>
                                <li>
                                  <Link to="/about-us/jci-india">JCI India</Link>
                                </li>
                                <li>
                                  <Link to="/about-us/jci-india-foundation">JCI India Foundation</Link>
                                </li>
                                <li>
                                  <Link to="/about-us/committee-members">Committee Members</Link>
                                </li>
                                <li>
                                  <Link to="/about-us/know-jci-india-foundation">Know the Foundation</Link>
                                </li>
                                <li>
                                  <Link to="/about-us/why-should-contribute">Why should contribute</Link>
                                </li>
                                <li>
                                  <Link to="/about-us/why-should-i-donate">Why should I Donate</Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#none">Titles</a>
                              <ul className="dropdown-menu-item">
                                <li>
                                  <Link to="/all-titles">All Titles</Link>
                                </li>
                                <li>
                                  <Link to="/titles/hgf-title">HGF Title</Link>
                                </li>
                                <li>
                                  <Link to="/titles/jfm-title">JFM Title</Link>
                                </li>
                                <li>
                                  <Link to="/titles/jfd-title">JFD Title</Link>
                                </li>
                                <li>
                                  <Link to="/titles/jfa-title">JFA Title</Link>
                                </li>
                                <li>
                                  <Link to="/titles/jfp-title">JFP Title</Link>
                                </li>
                                <li>
                                  <Link to="/titles/jff-title">JFF Title</Link>
                                </li>
                                <li>
                                  <Link to="/titles/jfs-title">JFS Title</Link>
                                </li>
                                <li>
                                  <Link to="/titles/jfg-title">JFG Title</Link>
                                </li>
                                <li>
                                  <Link to="/titles/rpp-title">RPP Title</Link>
                                </li>
                                <li>
                                  <Link to="/titles/jfr-title">JFR Title</Link>
                                </li>
                                <li>
                                  <Link to="/titles/jfj-title">JFJ Title</Link>
                                </li>
                                <li>
                                  <Link to="/titles/jfe-title">JFE Title</Link>
                                </li>
                                <li>
                                  <Link to="/titles/jfk-title">JFK Title</Link>
                                </li>
                                <li>
                                  <Link to="/titles/jfi-title">JFI Title</Link>
                                </li>
                                <li>
                                  <Link to="/titles/jfc-title">JFC Title</Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#none">Contributors</a>
                              <ul className="dropdown-menu-item">
                                <li>
                                  <Link to="/contributors/hgf-contributors">HGF Contributors</Link>
                                </li>
                                <li>
                                  <Link to="/contributors/jfm-contributors">JFM Contributors</Link>
                                </li>
                                <li>
                                  <Link to="/contributors/jfd-contributors">JFD Contributors</Link>
                                </li>
                                <li>
                                  <Link to="/contributors/jfa-contributors">JFA Contributors</Link>
                                </li>
                                <li>
                                  <Link to="/contributors/jfp-contributors">JFP Contributors</Link>
                                </li>
                                <li>
                                  <Link to="/contributors/jff-contributors">JFF Contributors</Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#none">Wall of Honour</a>
                              <ul className="dropdown-menu-item">
                                <li>
                                  <Link to="/contributors/jfs-contributors">JFS Contributors</Link>
                                </li>
                                <li>
                                  <Link to="/contributors/jfg-contributors">JFG Contributors</Link>
                                </li>
                                <li>
                                  <Link to="/contributors/rpp-contributors">RPP Contributors</Link>
                                </li>
                                <li>
                                  <Link to="/contributors/jfr-contributors">JFR Contributors</Link>
                                </li>
                                <li>
                                  <Link to="/contributors/jfj-contributors">JFJ Contributors</Link>
                                </li>
                                <li>
                                  <Link to="/contributors/jfe-contributors">JFE Contributors</Link>
                                </li>
                                <li>
                                  <Link to="/contributors/jfk-contributors">JFK Contributors</Link>
                                </li>
                                <li>
                                  <Link to="/contributors/jfi-contributors">JFI Contributors</Link>
                                </li>
                                <li>
                                  <Link to="/contributors/jfc-contributors">JFC Contributors</Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#none">Gallery</a>
                              <ul className="dropdown-menu-item">
                                <li>
                                  <Link to="/gallery/photo-gallery">Photo Gallery</Link>
                                </li>
                                <li>
                                  <Link to="/gallery/video-gallery">Video Gallery</Link>
                                </li>
                              </ul>
                            </li>
                            {/* <li>
                              <Link to="#none">Events</Link>
                            </li> */}
                            <li>
                              <Link to="/contact">contact</Link>
                            </li>
                            {/*
                            <li>
                              <Link to="/index2">Home 2</Link>
                            </li>
                            <li>
                              <a href="#none">news</a>
                              <ul className="dropdown-menu-item">
                                <li>
                                  <Link to="/news">news</Link>
                                </li>
                                <li>
                                  <Link to="/single-news">news detail</Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#none">pages</a>
                              <ul className="dropdown-menu-item">
                                <li>
                                  <Link to="/about">about</Link>
                                </li>
                                <li>
                                  <Link to="/gallery">gallery</Link>
                                </li>
                                <li>
                                  <Link to="/volunteer">
                                    become a volunteer
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/team">our team</Link>
                                </li>
                                <li>
                                  <Link to="/sponsor">sponsors</Link>
                                </li>
                              </ul>
                            </li> */}
                          </ul>
                        </nav>
                      </div>
                    </div>
                    <div className="mobile-menu-toggle">
                      <i className="fa fa-bars"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="side-nav-container">
            <div className="humburger-menu">
              <div className="humburger-menu-lines side-menu-close"></div>
            </div>
            <div className="side-menu-wrap">
              <div className="side-btn">
                <Link to="/donate" className="theme-btn">
                  donate now
                </Link>
              </div>
              <ul className="side-social">
                <li>
                  <a href="#none">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#none">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#none">
                    <i className="fa fa-youtube-play"></i>
                  </a>
                </li>
                <li>
                  <a href="#none">
                    <i className="fa fa-google-plus"></i>
                  </a>
                </li>
              </ul>
              <ul className="side-menu-ul">
                <li className="sidenav__item">
                  <Link to="/">Home</Link>
                </li>
                <li className="sidenav__item">
                  <a href="#none">About us</a>
                  <span className="menu-plus-icon"></span>
                  <ul className="side-sub-menu">
                    <li>
                      <Link to="/about-us/why-jci">Why JCI</Link>
                    </li>
                    <li>
                      <Link to="/about-us/jci-india">JCI India</Link>
                    </li>
                    <li>
                      <Link to="/about-us/jci-india-foundation">JCI India Foundation</Link>
                    </li>
                    <li>
                      <Link to="/about-us/committee-members">Committee Members</Link>
                    </li>
                    <li>
                      <Link to="/about-us/know-jci-india-foundation">Know the Foundation</Link>
                    </li>
                    <li>
                      <Link to="/about-us/why-should-contribute">Why should contribute</Link>
                    </li>
                    <li>
                      <Link to="/about-us/why-should-i-donate">Why should I Donate</Link>
                    </li>
                  </ul>
                </li>
                <li className="sidenav__item">
                  <a href="#none">Titles</a>
                  <span className="menu-plus-icon"></span>
                  <ul className="side-sub-menu">
                    <li>
                      <Link to="/all-titles">All Titles</Link>
                    </li>
                    <li>
                      <Link to="/titles/hgf-title">HGF Title</Link>
                    </li>
                    <li>
                      <Link to="/titles/jfm-title">JFM Title</Link>
                    </li>
                    <li>
                      <Link to="/titles/jfd-title">JFD Title</Link>
                    </li>
                    <li>
                      <Link to="/titles/jfa-title">JFA Title</Link>
                    </li>
                    <li>
                      <Link to="/titles/jfp-title">JFP Title</Link>
                    </li>
                    <li>
                      <Link to="/titles/jff-title">JFF Title</Link>
                    </li>
                    <li>
                      <Link to="/titles/jfs-title">JFS Title</Link>
                    </li>
                    <li>
                      <Link to="/titles/jfg-title">JFG Title</Link>
                    </li>
                    <li>
                      <Link to="/titles/rpp-title">RPP Title</Link>
                    </li>
                    <li>
                      <Link to="/titles/jfr-title">JFR Title</Link>
                    </li>
                    <li>
                      <Link to="/titles/jfj-title">JFJ Title</Link>
                    </li>
                    <li>
                      <Link to="/titles/jfe-title">JFE Title</Link>
                    </li>
                    <li>
                      <Link to="/titles/jfk-title">JFK Title</Link>
                    </li>
                    <li>
                      <Link to="/titles/jfi-title">JFI Title</Link>
                    </li>
                    <li>
                      <Link to="/titles/jfc-title">JFC Title</Link>
                    </li>
                  </ul>
                </li>
                <li className="sidenav__item">
                  <a href="#none">Contributors</a>
                  <span className="menu-plus-icon"></span>
                  <ul className="side-sub-menu">
                    <li>
                      <Link to="/contributors/hgf-contributors">HGF Contributors</Link>
                    </li>
                    <li>
                      <Link to="/contributors/jfm-contributors">JFM Contributors</Link>
                    </li>
                    <li>
                      <Link to="/contributors/jfd-contributors">JFD Contributors</Link>
                    </li>
                    <li>
                      <Link to="/contributors/jfa-contributors">JFA Contributors</Link>
                    </li>
                    <li>
                      <Link to="/contributors/jfp-contributors">JFP Contributors</Link>
                    </li>
                    <li>
                      <Link to="/contributors/jff-contributors">JFF Contributors</Link>
                    </li>
                  </ul>
                </li>
                <li className="sidenav__item">
                  <a href="#none">Wall of Honour</a>
                  <span className="menu-plus-icon"></span>
                  <ul className="side-sub-menu">
                    <li>
                      <Link to="/contributors/jfs-contributors">JFS Contributors</Link>
                    </li>
                    <li>
                      <Link to="/contributors/jfg-contributors">JFG Contributors</Link>
                    </li>
                    <li>
                      <Link to="/contributors/rpp-contributors">RPP Contributors</Link>
                    </li>
                    <li>
                      <Link to="/contributors/jfr-contributors">JFR Contributors</Link>
                    </li>
                    <li>
                      <Link to="/contributors/jfj-contributors">JFJ Contributors</Link>
                    </li>
                    <li>
                      <Link to="/contributors/jfe-contributors">JFE Contributors</Link>
                    </li>
                    <li>
                      <Link to="/contributors/jfk-contributors">JFK Contributors</Link>
                    </li>
                    <li>
                      <Link to="/contributors/jfi-contributors">JFI Contributors</Link>
                    </li>
                    <li>
                      <Link to="/contributors/jfc-contributors">JFC Contributors</Link>
                    </li>
                  </ul>
                </li>
                <li className="sidenav__item">
                  <a href="#none">Gallery</a>
                  <span className="menu-plus-icon"></span>
                  <ul className="side-sub-menu">
                    <li>
                      <Link to="/gallery/photo-gallery">Photo Gallery</Link>
                    </li>
                    <li>
                      <Link to="/gallery/video-gallery">Video Gallery</Link>
                    </li>
                  </ul>
                </li>
                {/* <li className="sidenav__item">
                  <Link to="#none">Events</Link>
                </li> */}
                <li className="sidenav__item">
                  <Link to="/contact">contact</Link>
                </li>
              </ul>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default NavOne;
